#root
{
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

/** Scrollbar */
/* width */
::-webkit-scrollbar {
}

/* Track */
::-webkit-scrollbar-track {
    background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #b9b9b9;
    border-radius: 2.5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: none;
}

#menu-bar-top {
  //height: calc(var(--menu-scrollTop) * 1px);
  opacity: calc(var(--menu-scrollTop) / 112);
}

#top-menu-bar-logo {
  padding-left: calc(100 / var(--menu-scrollTop) * 2.8px - 2.5px);
  opacity: calc(var(--menu-scrollTop) * var(--menu-scrollTop) / 1000);
}

#top-menu-logo {
  //height: calc(var(--menu-scrollTop) * 1px);
  opacity: calc(1 - (var(--menu-scrollTop) * var(--menu-scrollTop) / 1500));
  //transform:
    //translateX(calc(var(--menu-scrollTop) * -0.16vw))
    //scale(calc(1 - (var(--menu-scrollTop) * var(--menu-scrollTop) * 0.0001)));
}

/* webkit arrow buttons in number input */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* mozilla arrow buttons in number input */
input[type="number"] {
  -moz-appearance: textfield;
}

p, h1, h2, h3, h4, h5, h6, caption {
  user-select: none;
}
